import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useUploadImagesExperts } from "./useUploadImagesExperts";
import {
  faCamera,
  faPaperPlane,
  faFileAudio,
} from "@fortawesome/free-solid-svg-icons";
import { uploadImagesCoverTypes } from "./constants";
import { FC } from "react";
import { UploadImagesExpertsProps } from "./types";
import { $colorVariables } from "../../../../../../../styles/colorVariables";
import IconComponent from "../../../../../../common/icon";
import { StyledBlockUploadElements } from "./styles";
import { StyledDivider } from "../../../../../../../styles/common/styles";

const UploadImagesExperts: FC<UploadImagesExpertsProps> = ({
  expertId,
  userId,
  uuid,
}) => {
  const {
    uploadImageHandler,
    coverType,
    fileImage,
    avatar,
    audio,
    uploadAvatarHandler,
    handleInputFileChange,
    handleCoverTypeChange,
    resetFilds
  } = useUploadImagesExperts(expertId, userId, uuid);

  return (
    <>
      <StyledBlockUploadElements>
        <IconButton color="primary" component="label">
          <input
            hidden
            accept="image/*"
            type="file"
            onChange={(event) =>
              handleInputFileChange("handleImageChange", event)
            }
          />
          <Typography sx={{ mr: 2 }}>Загрузить файл</Typography>
          <IconComponent icon={faCamera} />
        </IconButton>
        <FormControl sx={{ width: "200px", ml: 2, mr: 2 }}>
          <InputLabel id="demo-simple-select-label">
            {coverType || "cover Type"}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={coverType}
            label={coverType || "Cover type"}
            onChange={handleCoverTypeChange}
          >
            {uploadImagesCoverTypes.map((coverType) => {
              return (
                <MenuItem key={coverType} value={coverType}>
                  {coverType}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {fileImage && (
          <Typography
            sx={{
              color: $colorVariables.$blue02,
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            File name: {fileImage?.name}
          </Typography>
        )}
        <Box display={"flex"} gap={2}>
          <Button variant="contained" onClick={resetFilds}>
            Reset
          </Button>
          <Button
            onClick={uploadImageHandler}
          disabled={!fileImage || !coverType}
            variant="contained"
          >
            <Typography sx={{ mr: 2 }}>Сохранить</Typography>
            <IconComponent
              icon={faPaperPlane}
              size={"2x"}
              title="send"
              cursor={"pointer"}
            />
          </Button>
        </Box>
      </StyledBlockUploadElements>

      <StyledDivider>Загрузка аватара</StyledDivider>

      <StyledBlockUploadElements>
        <IconButton color="primary" component="label">
          <input
            hidden
            accept="image/*"
            type="file"
            onChange={(event) =>
              handleInputFileChange("handleAvatarChange", event)
            }
          />
          <Typography sx={{ mr: 2 }}>Загрузить файл</Typography>
          <IconComponent icon={faCamera} />
        </IconButton>

        {avatar && (
          <Typography
            sx={{
              color: $colorVariables.$blue02,
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            File name: {avatar?.name}
          </Typography>
        )}

        <Box display={"flex"} gap={2}>
          <Button variant="contained" onClick={resetFilds}>
            Reset
          </Button>

          <Button
            onClick={uploadAvatarHandler}
            disabled={!avatar}
            variant="contained"
          >
            <Typography sx={{ mr: 2 }}>Сохранить</Typography>
            <IconComponent
              icon={faPaperPlane}
              size={"2x"}
              title="send"
              cursor={"pointer"}
            />
          </Button>
        </Box>
      </StyledBlockUploadElements>

      <StyledDivider>Загрузить аудио приветствие</StyledDivider>

      <StyledBlockUploadElements>
        <IconButton color="primary" component="label">
          <input
            hidden
            accept="audio/*"
            type="file"
            onChange={(event) =>
              handleInputFileChange("handleAudioChange", event)
            }
          />
          <Typography sx={{ mr: 2 }}>Загрузить файл</Typography>
          <IconComponent icon={faFileAudio} />
        </IconButton>

        <Box display={"flex"} gap={2}>
          <Button variant="contained" onClick={resetFilds}>
            Reset
          </Button>

          <Button
            onClick={uploadAvatarHandler}
            disabled={!audio}
            variant="contained"
          >
            <Typography sx={{ mr: 2 }}>Сохранить</Typography>
            <IconComponent
              icon={faPaperPlane}
              size={"2x"}
              title="send"
              cursor={"pointer"}
            />
          </Button>
          {audio && (
            <Typography
              sx={{
                color: $colorVariables.$blue02,
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              File name: {audio?.name}
            </Typography>
          )}
        </Box >
      </StyledBlockUploadElements>
    </>
  );
};

export default UploadImagesExperts;
