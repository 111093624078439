/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BonusAffectedObjects {
  balance = "balance",
  calls = "calls",
  chats = "chats",
  video_calls = "video_calls",
}

export enum BonusFormula {
  fixed = "fixed",
  percent = "percent",
}

export enum BonusType {
  deposit = "deposit",
  sale = "sale",
}

export enum BucketKey {
  images = "images",
  promocodes = "promocodes",
}

export enum Currency {
  EUR = "EUR",
  RUB = "RUB",
  USD = "USD",
}

export enum ExpertCoverImageType {
  Horizontal1x = "Horizontal1x",
  Horizontal2x = "Horizontal2x",
  Vertical1x = "Vertical1x",
  Vertical2x = "Vertical2x",
  Vertical3x = "Vertical3x",
}

export enum ExpertState {
  available = "available",
  busy = "busy",
  offline = "offline",
}

export enum Gender {
  CUSTOM = "CUSTOM",
  FEMALE = "FEMALE",
  MALE = "MALE",
  UNKNOWN = "UNKNOWN",
}

export enum OrderEnum {
  asc = "asc",
  desc = "desc",
}

export enum PaymentButtonType {
  moneyOnly = "moneyOnly",
  moneyWithMinutes = "moneyWithMinutes",
}

export enum PaymentFormType {
  appointment = "appointment",
  profile = "profile",
  session = "session",
}

export enum PaymentMethod {
  NewCard = "NewCard",
  SavedCard = "SavedCard",
}

export enum PaymentProvider {
  CloudPayments = "CloudPayments",
  Other = "Other",
  SoftlinePayments = "SoftlinePayments",
  SomPayments = "SomPayments",
}

export enum PaymentState {
  Created = "Created",
  Failed = "Failed",
  InProgress = "InProgress",
  Refunded = "Refunded",
  Success = "Success",
}

export enum PromoActionsStatus {
  active = "active",
  inactive = "inactive",
}

export enum RedeemAbility {
  one_per_user = "one_per_user",
  one_time = "one_time",
}

export enum ReferralProgramStatus {
  active = "active",
  blocked = "blocked",
  not_available = "not_available",
  unblocked = "unblocked",
}

export enum ReviewStatus {
  DELETED = "DELETED",
  ON_MODERATION = "ON_MODERATION",
  PUBLISHED = "PUBLISHED",
  UNKNOWN = "UNKNOWN",
}

export enum ScheduleStatus {
  ScheduleStatusCompleted = "ScheduleStatusCompleted",
  ScheduleStatusInProgress = "ScheduleStatusInProgress",
  ScheduleStatusScheduled = "ScheduleStatusScheduled",
  ScheduleStatusUnknown = "ScheduleStatusUnknown",
}

export enum ScheduleType {
  ScheduleTypePrivate = "ScheduleTypePrivate",
  ScheduleTypePublic = "ScheduleTypePublic",
  ScheduleTypeUnknown = "ScheduleTypeUnknown",
  ScheduleTypeWorkshop = "ScheduleTypeWorkshop",
}

export enum SessionType {
  CALL = "CALL",
  CHAT = "CHAT",
  PHONE_CALL = "PHONE_CALL",
  VIDEO_CALL = "VIDEO_CALL",
}

export enum SortByEnum {
  date = "date",
  id = "id",
  rate = "rate",
  rating = "rating",
}

export enum UserLoginType {
  email = "email",
  facebook = "facebook",
  google = "google",
  vk = "vk",
}

export interface AvatarInput {
  avatar: any;
}

export interface BlockUserFilter {
  userID?: any | null;
  isBlocked?: boolean | null;
  dateFrom?: string | null;
  dateTo?: string | null;
}

export interface BonusFilter {
  name?: string | null;
  type?: string | null;
}

export interface CashbackRulesFilter {
  isActive?: boolean | null;
}

export interface CreateScheduleIntervalInput {
  expertID: any;
  start: any;
  end: any;
  type?: ScheduleType | null;
  status?: ScheduleStatus | null;
}

export interface DateFilter {
  earlierThan?: number | null;
  laterThan?: number | null;
}

export interface DateFilterS {
  earlierThan?: any | null;
  laterThan?: any | null;
}

export interface DelCashbackRule {
  ruleID: number;
}

export interface DelPaymentFormButtonTemplate {
  buttonId: number;
}

export interface DeleteAccountParams {
  userID: number;
  reason: string;
}

export interface ExpertCoverImage {
  file: any;
  coverType: ExpertCoverImageType;
}

export interface ExpertFilter {
  scopes?: number[] | null;
  onlineOnly?: boolean | null;
  skills?: number[] | null;
  gender?: Gender | null;
  maxRate?: number | null;
  sessionType?: SessionType[] | null;
  methods?: number[] | null;
}

export interface ExpertTrialAllowanceParams {
  id: number;
  video?: boolean | null;
  phone?: boolean | null;
  chat?: boolean | null;
}

export interface GetScheduleFilter {
  DateFilter?: DateFilterS | null;
  ExpertFilter: any[];
}

export interface GetWhiteListIPFilter {
  ip?: string | null;
  updatedBy?: string | null;
  createdBy?: string | null;
  dateFrom?: any | null;
  dateTo?: any | null;
}

export interface MethodFilter {
  id?: number[] | null;
}

export interface NewBlockUser {
  userID: any;
  reason: string;
  blockUntil: string;
}

export interface NewBonus {
  name: string;
  type: BonusType;
  affectedObjects?: BonusAffectedObjects[] | null;
  formula: BonusFormula;
  amount: number;
  lifetimePeriod: number;
}

export interface NewCashbackRule {
  lowerBoundary: number;
  upperBoundary: number;
  bonusID: any;
}

export interface NewExpert {
  email: string;
  password: string;
  name: string;
  description: string;
  scopes?: number[] | null;
  skills?: number[] | null;
  gender: Gender;
  methods?: number[] | null;
  title: string;
  careerStartDate: string;
  chatRate?: number | null;
  callRate?: number | null;
  phoneCallRate?: number | null;
  videoCallRate?: number | null;
  id?: number | null;
  uuid?: string | null;
  tg?: string | null;
}

export interface NewPaymentFormButtonTemplate {
  paymentFormID: number;
  ButtonType: PaymentButtonType;
  MinutesAmount?: number | null;
  MoneyAmount?: number | null;
}

export interface NewPromo {
  bonusID: any;
  name: string;
  redeemAbility: RedeemAbility;
  generatePattern?: string | null;
  generateCount: number;
  codeLength: number;
  startDate: string;
  endDate: string;
}

export interface PaymentFormTemplatesFilter {
  ID?: number | null;
}

export interface PaymentsFilter {
  userID?: number | null;
  state?: PaymentState | null;
  method?: PaymentMethod | null;
  provider?: PaymentProvider | null;
}

export interface PromoCodeFilter {
  promoID?: any | null;
  code?: string | null;
  userID?: any | null;
  dateFrom?: any | null;
  dateTo?: any | null;
}

export interface PromoFilter {
  bonusID?: any | null;
  name?: string | null;
  redeemAbility?: (string | null)[] | null;
  status?: (string | null)[] | null;
  dateFrom?: string | null;
  dateTo?: string | null;
}

export interface ReviewFilter {
  statusFilter?: ReviewStatus[] | null;
  dateFilter?: DateFilter | null;
  expertFilter?: string[] | null;
  clientFilter?: string[] | null;
  gradeFilter?: number[] | null;
  isPinned?: boolean | null;
}

export interface ScopeFilter {
  id?: number[] | null;
}

export interface SkillFilter {
  id?: number[] | null;
}

export interface SortParameters {
  sortBy: SortByEnum;
  order: OrderEnum;
}

export interface UpdCashbackRule {
  id: number;
  lowerBoundary?: number | null;
  upperBoundary?: number | null;
  bonusID?: any | null;
  isActive?: boolean | null;
}

export interface UpdPaymentFormButtonTemplate {
  buttonID: number;
  buttonType?: PaymentButtonType | null;
  minutesAmount?: number | null;
  moneyAmount?: number | null;
}

export interface UpdatePromo {
  promoID: any;
  name?: string | null;
  generateCount?: number | null;
  startDate?: string | null;
  endDate?: string | null;
}

export interface UpdateScheduleIntervalInput {
  id: any;
  start: any;
  end: any;
  type?: ScheduleType | null;
  status?: ScheduleStatus | null;
}

export interface WhiteListParams {
  allowedAccountCount: number;
  ip: string;
  reason: string;
}

export interface WhiteListParamsFilter {
  id: string;
  allowedAccountCount?: number | null;
  reason?: string | null;
}

export interface addFreeMinutesInput {
  userIDs: number[];
  Amount: number;
  Reason: string;
}

export interface bindAffisePromocodeInput {
  promocode: string;
  offerId: string;
  affiliateId: string;
}

export interface composeReportInput {
  date: any;
}

export interface correctBalanceInput {
  userID: number;
  Amount: number;
  Reason: string;
}

export interface getReportInput {
  date: any;
}

export interface getUserInfoInput {
  UserID: number;
}

export interface idInput {
  id?: number | null;
  uuid?: any | null;
}

export interface saveReportInput {
  Date: any;
  NewUsersCount: number;
  Revenue: number;
  PurchaseCount: number;
  ExpertSpends: number;
  MarketingSpends: number;
}

export interface updateExpertInput {
  email?: string | null;
  password?: string | null;
  name: string;
  description: string;
  scopes: number[];
  skills: number[];
  gender: Gender;
  methods: number[];
  title: string;
  careerStartDate: string;
  chatRate: number;
  callRate?: number | null;
  phoneCallRate?: number | null;
  videoCallRate?: number | null;
  id: string;
  tg?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
