import { TableCell } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledTableCellBody = styled(TableCell)<any>(() => ({
  maxWidth: "200px",
  minWidth: "100px",

  [`&.MuiTableRow-root`]: {
    borderCollapse: "inherit !important",
  },
}));
