import { useMutation } from "@apollo/client";
import { useContext, useState, useEffect } from "react";
import globalDataCTX from "../../../../../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../../../../../contexts/globalContext/types";
import { ExpertCoverImageType } from "../../../../../../../globalTypes";
import { GET_ALL_EXPERTS } from "../../../../../graphql/GET_EXPERTS.graphql";
import { UPLOAD_AVATAR_USER } from "../../../../../graphql/UPLOAD_AVATAR_USER.graphql";
import { UPLOAD_EXPERTS_IMAGES } from "../../../../../graphql/UPLOAD_EXPERTS_IMAGES.graphql";
import {
  setAvatar,
  setAvatarVariables,
} from "../../../../../graphql/__generated__/setAvatar";
import {
  uploadExpertCoverImage,
  uploadExpertCoverImageVariables,
} from "../../../../../graphql/__generated__/uploadExpertCoverImage";

export const useUploadImagesExperts = (
  expertId: number,
  userId: number,
  uuid: string
) => {
  const { setNotificationMsg, setNotificationEvent } =
    useContext(globalDataCTX);
  const [fileImage, setfilImage] = useState<File | null>(null);
  const [avatar, setAvatar] = useState<File | null>(null);
  const [audio, setAudio] = useState<File | null>(null);
  const [coverType, setCoverType] = useState<ExpertCoverImageType | null>(null);

  const [uploadImage, { data: uploadImageData, error: errorImage }] =
    useMutation<uploadExpertCoverImage, uploadExpertCoverImageVariables>(
      UPLOAD_EXPERTS_IMAGES
    );

  const resetFilds = () => {
    setCoverType(null);
    setfilImage(null);
    setAvatar(null);
  }

  const [uploadAvatar, { data: uploadAvatarData, error: errorAvatar }] =
    useMutation<setAvatar, setAvatarVariables>(UPLOAD_AVATAR_USER);

  useEffect(() => {
    if (errorImage || errorAvatar) {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(errorImage?.message || errorAvatar?.message || "");
    } else if (uploadImageData || uploadAvatarData) {
      setNotificationMsg(
        `Загружено файл : ${fileImage?.name || avatar?.name || audio?.name} `
      );
      setNotificationEvent(notificationEventType.success);
    } else {
      setNotificationEvent(notificationEventType.noEvent);
      setNotificationMsg("");
    }
  }, [
    audio?.name,
    avatar?.name,
    errorAvatar,
    errorImage,
    fileImage?.name,
    setNotificationEvent,
    setNotificationMsg,
    uploadAvatarData,
    uploadImageData,
  ]);

  const uploadImageHandler = () => {
    uploadImage({
      variables: {
        expertID: expertId,
        cover: {
          coverType: coverType!,
          file: fileImage,
        },
      },
    });
  };

  const uploadAvatarHandler = () => {
    uploadAvatar({
      variables: {
        user: {
          id: userId,
          uuid: uuid,
        },
        avatar: {
          avatar: avatar,
        },
      },
      refetchQueries: [
        {
          query: GET_ALL_EXPERTS,
        },
      ],
    });
  };

  const handleInputFileChange = (
    inputType: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    switch (inputType) {
      case "handleImageChange":
        if (e.target.files && e.target.files[0]) {
          setfilImage(e.target.files[0]);
        }
        break;
      case "handleAvatarChange":
        if (e.target.files && e.target.files[0]) {
          setAvatar(e.target.files[0]);
        }
        break;
      case "handleAudioChange":
        if (e.target.files && e.target.files[0]) {
          setAudio(e.target.files[0]);
        }
        break;
      default:
        console.log("Unknown function name");
    }
  };

  const handleCoverTypeChange = (event: any) => {
    setCoverType(event?.target.value);
  };

  return {
    coverType,
    fileImage,
    avatar,
    audio,
    resetFilds,
    setCoverType,
    setfilImage,
    setAvatar,
    uploadImageHandler,
    uploadAvatarHandler,
    handleInputFileChange,
    handleCoverTypeChange,
  };
};
