import { TabelHeaderNamesTypes } from "./types";

const tabelHeaderNames: TabelHeaderNamesTypes[] = [
  {
    name: "ID",
  },
  {
    name: "Эксперт ",
  },
  {
    name: "Рейтинг",
  },
  {
    name: "Расписание",
  },
];

export default tabelHeaderNames;
