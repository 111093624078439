import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import ReviewsCTX from "../../../contexts/reviewsConext/ReviewsCTX";
import { ReviewsActionTypes } from "../../../contexts/reviewsConext/types";
import { GET_ALL_EXPERTS } from "../../experts/graphql/GET_EXPERTS.graphql";
import {
  getExperts,
  getExpertsVariables,
} from "../../experts/graphql/__generated__/getExperts";

export const useFiltersPanel = () => {
  const { dispatch } = useContext(ReviewsCTX);
  const [allExperts, { data: allExpertsData }] = useLazyQuery<
    getExperts,
    getExpertsVariables
  >(GET_ALL_EXPERTS);

  const resetFiltersHandler = () => {
    dispatch({
      type: ReviewsActionTypes.RESET_FILTERS,
    });
  };

  useEffect(() => {
    allExperts();
  }, [allExperts]);

  return {
    allExperts: allExpertsData?.getExperts,
    resetFiltersHandler,
  };
};
