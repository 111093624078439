import { useLazyQuery } from "@apollo/client"
import { GET_EXPERT_FOR_UPDATE } from "../graphql/GET_EXPERT_FOR_UPDATE"
import { getExpertForUpdate, getExpertForUpdateVariables } from "../graphql/__generated__/getExpertForUpdate"
import { useParams } from "react-router-dom";
import { useEffect } from "react";

export const useCreateUpdateExpert = () => {
    const { expertId } = useParams();
    const [getExpertDataForUpdate, { data: expertData, loading: loadingExpertData }] = useLazyQuery<getExpertForUpdate, getExpertForUpdateVariables>(GET_EXPERT_FOR_UPDATE)

    useEffect(() => {
        if (expertId) {
            getExpertDataForUpdate({
                variables: {
                    id: Number(expertId)
                }
            })
        }
    }, [expertId, getExpertDataForUpdate])

    return {
        expertDataForUpdate: expertData?.getExpert,
        loadingExpertData
    }
}