import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useMemo, useState } from "react";
import globalDataCTX from "../../contexts/globalContext/globalDataCTX";
import { GET_ALL_EXPERTS } from "./graphql/GET_EXPERTS.graphql";
import {
  getExperts,
  getExpertsVariables,
} from "./graphql/__generated__/getExperts";

export const useExportsTable = () => {
  const { setLoading, loading } = useContext(globalDataCTX);
  const [searchText, setSearchText] = useState<string>("");

  const [getAllExperts, { data, loading: loadingExperts }] = useLazyQuery<
    getExperts,
    getExpertsVariables
  >(GET_ALL_EXPERTS);
  const allExpertData = data?.getExperts?.edges;

  const searchExpertData = useMemo(() => {
    const searchExpertData = data?.getExperts?.edges.filter((item) => {
      return item?.node.name.toLowerCase().includes(searchText);
    });

    return searchExpertData;
  }, [data?.getExperts?.edges, searchText]);

  useEffect(() => {
    getAllExperts();
    setLoading(loadingExperts);
  }, [getAllExperts, loadingExperts, setLoading]);

  return {
    allExpertData: searchText ? searchExpertData : allExpertData,
    searchText,
    loading,
    setSearchText
  };
};
