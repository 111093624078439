import { gql } from "@apollo/client";

export const GET_ALL_EXPERTS = gql`
  query getExperts(
    $after: String
    $first: Int
    $filter: ExpertFilter
    $sorting: SortParameters
  ) {
    getExperts(
      after: $after
      first: $first
      filter: $filter
      sorting: $sorting
    ) {
      edges {
        node {
          id
          uuid
          name
          description
          image(size: 100) {
            onex
          }
          cover {
            horizontal1x
            horizontal2x
            vertical1x
            vertical2x
            vertical3x
          }
          rates {
            chat
            call
            videoCall
            phoneCall
          }
          gender
          userID
          rating
          skills {
            id
            name
            badge
            description
            expertsCount
          }
          methods {
            id
            name
            description
            expertsCount
          }
          totalSessionsCount
          careerStartDate
          experienceInYears
          title
          state
          reviewsCount
          scopes {
            id
            name
            description
            scopesCount
          }
          tg
          trialAllowance {
            video
            chat
            phone
          }
        }
      }
    }
  }
`;
